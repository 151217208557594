import { normalize } from 'normalizr';
import { request } from 'utils';

import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  SET_SELECTED_SERVICE,
  FETCH_UNSUBSCIPTION_POOL_REQUEST,
  FETCH_UNSUBSCIPTION_POOL_SUCCESS,
  FETCH_UNSUBSCIPTION_POOL_FAILURE,
} from 'actionTypes';

import { serviceSchema } from 'schemas';

import {
  makeGetServices,
  makeGetTokensData,
  makeGetUnsubscribePoll,
} from 'selectors';

import { jwtRequest } from './token';

const getServices = makeGetServices();
const getUnsubscribePoll = makeGetUnsubscribePoll();
const getTokensData = makeGetTokensData();

const getShouldFetchServices = (state, props) => {
  const { isFetching, isLoaded } = getServices(state, props) || {};

  return !isFetching && !isLoaded;
};

export const fetchServices = props => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!getShouldFetchServices(state, props)) {
      return true;
    }

    dispatch({
      type: FETCH_SERVICES_REQUEST,
      payload: {},
    });

    const requestURL = `${process.env.APP_SUBCRIPTIONS_API_ROOT}/services`;

    const data = await request(requestURL, {
      headers: {
        'Accept-Language': props.intl.locale,
      },
    });

    const {
      entities: { service: entities = {} },
      result: ids = [],
    } = normalize(data, serviceSchema);

    return dispatch({
      type: FETCH_SERVICES_SUCCESS,
      payload: {
        entities,
        ids,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SERVICES_FAILURE,
      payload: {},
    });
  }
};

export const setSelectedServiceId = props => async dispatch => {
  return dispatch({
    type: SET_SELECTED_SERVICE,
    payload: {
      selectedServiceId: props.serviceId,
    },
  });
};

const getShouldFetchUnsubscribePoll = (state, props) => {
  const { isFetching, isLoaded } = getUnsubscribePoll(state, props) || {};

  return !isFetching && !isLoaded;
};

export const fetchUnsubscribePoll = props => async (dispatch, getState) => {
  try {
    const state = getState();

    const { token, refreshToken } = getTokensData(state, props);

    if (!getShouldFetchUnsubscribePoll(state, props) || !token) {
      return true;
    }

    dispatch({
      type: FETCH_UNSUBSCIPTION_POOL_REQUEST,
      payload: {},
    });

    // const data = await request(
    //   `${process.env.APP_SUBCRIPTIONS_API_ROOT}/services/${props.service.id}/unsubscribe-poll-options`,
    //   {
    //     headers: {
    //       'Accept-Language': props.intl.locale,
    //       Authorization: `${TOKEN_TYPE} ${token}`,
    //     },
    //   }
    // );

    const data = await jwtRequest({
      callbackUrl: `${process.env.APP_SUBCRIPTIONS_API_ROOT}/services/${props.service.id}/unsubscribe-poll-options`,
      token,
      refreshToken,
      locale: props.intl.locale,
      dispatch,
    });

    return dispatch({
      type: FETCH_UNSUBSCIPTION_POOL_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_UNSUBSCIPTION_POOL_FAILURE,
      payload: {},
    });
  }
};
