import { createSelector } from 'reselect';

const getUserPayments = state => state.user.payments;
const getTokens = state => state.token;

export const makeGetUserPayments = () => {
  return createSelector(getUserPayments, i => i || {});
};

export const getUserPaymentsEntities = createSelector(
  getUserPayments,
  data => data?.entities || {}
);

export const getUserPaymentsIds = createSelector(
  getUserPayments,
  data => data?.ids || []
);

export const getUserPaymentsList = createSelector(
  getUserPaymentsEntities,
  getUserPaymentsIds,
  (entities, ids) =>
    entities && ids.length
      ? ids.map(i => entities[i])?.sort((a, b) => (a.id < b.id ? 1 : -1))
      : []
);

export const makeGetTokens = () => {
  return createSelector(getTokens, tokens => tokens);
};

export const makeGetTokensData = () => {
  return createSelector(getTokens, tokens => tokens.data || {});
};
