import { GTM_EVENT_CATEGORY } from 'constants/index';

export const getGADataAttrs = ({
  category = GTM_EVENT_CATEGORY.adFreePage,
  label,
  action,
  show = false,
}) => {
  return {
    'data-gtm-ec': category,
    'data-gtm-el': label,
    'data-gtm-ea': action,
    'data-gtm-show': show ? 'show' : undefined,
  };
};

export const createGtmEvent = ({
  eventCategory = GTM_EVENT_CATEGORY.adFreePage,
  eventLabel,
  eventAction,
  eventShow,
}) => {
  (window.dataLayer = window.dataLayer || []).push({
    event: 'autoEvent',
    eventCategory,
    eventLabel,
    eventAction,
    ...(eventShow ? { eventShow } : {}),
  });
};
