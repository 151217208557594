import React, { memo } from 'react';
import PropTypes from 'prop-types';

import TemplateParamsContext from './context';

const TemplateParamsProvider = memo(({ children, ...props }) => (
  <TemplateParamsContext.Provider value={props}>
    {children}
  </TemplateParamsContext.Provider>
));

TemplateParamsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default TemplateParamsProvider;
