import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { request } from 'utils';
import { fetchUserSubscriptions } from 'actions';
import { MENU_ITEMS, TOKEN_TYPE } from '../constants';
import { makeGetTokensData } from '../selectors';
import { useSelectorProps } from './useSelectorProps';

const getTokensData = makeGetTokensData();

export const usePortmoneForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectorProps = useSelectorProps();

  const [isFetching, toggleFetching] = useState(false);

  const { token } = useSelector(state => getTokensData(state, selectorProps));

  const fetchPortmoneForm = useCallback(
    async ({ service, tariffId }) => {
      toggleFetching(true);

      try {
        const { paymentForm } = await request(
          `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/subscriptions/service/${service.id}/tariff/${tariffId}`,
          {
            method: 'post',
            headers: {
              Authorization: `${TOKEN_TYPE} ${token}`,
            },
          }
        );

        if (paymentForm) {
          // eslint-disable-next-line no-eval
          eval(atob(paymentForm));

          setTimeout(() => {
            document.getElementById('portmonepaymentframe').style.display =
              'block';
          }, 100);

          window.PG.success(async () => {
            try {
              dispatch(fetchUserSubscriptions(selectorProps)).then(() => {
                history.push(MENU_ITEMS.mySubscriptionsPage.url);
              });
            } catch (err) {
              history.push(MENU_ITEMS.mySubscriptionsPage.url);
            }
          });

          // // if (!window.PG) return;
          //
          // window.PG.success(() => {
          //   dispatch(fetchUserSubscriptions(selectorProps));
          // });
        }
      } catch (error) {
        console.log('error portmone form:', error);
      } finally {
        toggleFetching(false);
        // history.push(MENU_ITEMS.mySubscriptionsPage.url);
      }
    },
    [token]
  );

  return [isFetching, fetchPortmoneForm];
};

export default usePortmoneForm;
