import { schema } from 'normalizr';
import { parseISO, format } from 'date-fns';

import { FULL_DATE_FORMAT } from 'constants/date';

export const userPayment = new schema.Entity(
  'userPayment',
  {},
  {
    processStrategy: item => {
      const parsedPaidAt = parseISO(item.paidAt);

      return {
        ...item,
        paidAt: format(new Date(parsedPaidAt), FULL_DATE_FORMAT),
      };
    },
  }
);

export const userPaymentSchema = [userPayment];
