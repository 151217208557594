import { fontFamilySecondary } from './fonts';
import { mediaQueryMobileLargeMin } from './mediaQueries';
import { colorBlack900, colorBlack800 } from './colors';

export const h1 = `
  text-align: center;
  margin-bottom: 16px;
  font: 700 24px/32px ${fontFamilySecondary};
  color: ${colorBlack900};

  @media ${mediaQueryMobileLargeMin} {
    font-size: 36px;
    line-height: 40px;
  }
`;

export const h2 = `
  ${h1}

  @media ${mediaQueryMobileLargeMin} {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

export const h3 = `
  font: 700 18px/24px ${fontFamilySecondary};
  color: ${colorBlack900};
  margin-bottom: 8px;
`;

export const paragraph = `
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack900};
  margin: 0;
`;

export const sectionDescription = `
  color: ${colorBlack800};
  text-align: center;
  font-size: 14px;
  line-height: 24px;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 16px;
    line-height: 28px;
  }
`;
