import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { StaticRouter, BrowserRouter } from 'react-router-dom';

import { SUPPORTED_LOCALES } from 'utils';

import { TemplateParamsProvider } from 'providers';

import translationsRU from 'translations/ru.json';
import translationsUK from 'translations/uk.json';

import App from 'components/App';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/ru');
  require('@formatjs/intl-pluralrules/dist/locale-data/uk');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/uk');
}

const translations = {
  ru: translationsRU,
  uk: translationsUK,
};

const Root = memo(
  ({
    store,
    locale,
    routerBasename,
    routerContext,
    routerLocation,
    env,
    templateParams,
  }) => {
    return (
      <ReduxProvider store={store}>
        <IntlProvider
          locale={locale}
          defaultLocale={locale}
          messages={translations[locale]}
          textComponent={Fragment}
          defaultRichTextElements={{
            span: i => <span>{i}</span>,
            br: () => <br />,
          }}
        >
          {env === 'server' ? (
            <StaticRouter
              location={routerLocation}
              context={routerContext}
              basename={routerBasename}
            >
              <TemplateParamsProvider {...templateParams}>
                <App />
              </TemplateParamsProvider>
            </StaticRouter>
          ) : (
            <BrowserRouter basename={routerBasename}>
              <TemplateParamsProvider {...templateParams}>
                <App />
              </TemplateParamsProvider>
            </BrowserRouter>
          )}
        </IntlProvider>
      </ReduxProvider>
    );
  }
);

Root.propTypes = {
  locale: PropTypes.oneOf(SUPPORTED_LOCALES).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  routerBasename: PropTypes.string.isRequired,
  routerContext({ env, ...props }, propName) {
    const { [propName]: propValue } = props;

    if (
      env === 'server' &&
      (propValue === undefined || typeof propValue !== 'object')
    ) {
      return new Error('Please provide routerContext!');
    }

    return null;
  },
  routerLocation({ env, ...props }, propName) {
    const { [propName]: propValue } = props;

    if (
      env === 'server' &&
      (propValue === undefined || typeof propValue !== 'string')
    ) {
      return new Error('Please provide routerLocation!');
    }

    return null;
  },
  env: PropTypes.oneOf(['client', 'server']).isRequired,
  templateParams: PropTypes.objectOf(PropTypes.any).isRequired,
};

Root.defaultProps = {
  routerContext: null,
  routerLocation: null,
};

export default Root;
