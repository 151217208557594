import { normalize } from 'normalizr';
import { stringify } from 'query-string';

import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_UNSUBSCRIPTIONS_REQUEST,
  FETCH_UNSUBSCRIPTIONS_SUCCESS,
  FETCH_UNSUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIBE_REQUEST,
  FETCH_SUBSCRIBE_SUCCESS,
  FETCH_SUBSCRIBE_FAILURE,
} from 'actionTypes';

import { SUBSCRIPTION_STATES, MENU_ITEMS } from 'constants/index';
import { subscriptionSchema } from 'schemas';
import { makeGetSubscriptions, makeGetTokensData } from 'selectors';
import { jwtRequest } from 'actions/token';

const getSubscriptions = makeGetSubscriptions();
const getTokensData = makeGetTokensData();

const getShouldFetchSubscriptions = (state, props) => {
  const { isFetching, isLoaded } = getSubscriptions(state, props) || {};

  return !isFetching && !isLoaded;
};

export const fetchUserSubscriptions = props => async (dispatch, getState) => {
  try {
    const state = getState();

    const { token, refreshToken } = getTokensData(state, props);

    if (!getShouldFetchSubscriptions(state, props) || !token) {
      return true;
    }

    dispatch({
      type: FETCH_SUBSCRIPTIONS_REQUEST,
      payload: {},
    });

    const requestURL = `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/subscriptions`;

    const data = await jwtRequest({
      callbackUrl: requestURL,
      token,
      refreshToken,
      locale: props.intl.locale,
      dispatch,
    });

    const {
      entities: { subscription: entities = {} },
      result: ids = [],
    } = normalize(data, subscriptionSchema);

    return dispatch({
      type: FETCH_SUBSCRIPTIONS_SUCCESS,
      payload: {
        entities,
        ids,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SUBSCRIPTIONS_FAILURE,
      payload: {},
    });
  }
};

export const getUnsubscriptionService = props => async (dispatch, getState) => {
  try {
    const state = getState();

    const { token, refreshToken } = getTokensData(state, props);

    const { id, pollAnswer } = props;

    dispatch({
      type: FETCH_UNSUBSCRIPTIONS_REQUEST,
      payload: {},
    });

    const callbackUrl = `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/subscriptions/${id}/cancel-subscription`;

    const data = await jwtRequest({
      callbackUrl,
      token,
      refreshToken,
      locale: props.intl.locale,
      dispatch,
      method: 'put',
      contentType: 'application/x-www-form-urlencoded',
      data: stringify({
        pollAnswer,
      }),
    });

    const subscriptionsEntities = {
      ...state.subscriptions.entities,
      [id]: data,
    };

    return dispatch({
      type: FETCH_UNSUBSCRIPTIONS_SUCCESS,
      payload: {
        entities: subscriptionsEntities,
      },
    });
  } catch (error) {
    console.log('FETCH_UNSUBSCRIPTIONS_FAILURE:', error);
    return dispatch({
      type: FETCH_UNSUBSCRIPTIONS_FAILURE,
      payload: {},
    });
  }
};

export const getSubscribeService = props => async (dispatch, getState) => {
  try {
    const state = getState();

    const subscriptions = getSubscriptions(state);

    dispatch({
      type: FETCH_SUBSCRIBE_REQUEST,
      payload: {},
    });

    const {
      entities: { subscription: entities = {} },
      result: ids = [],
    } = normalize([props], subscriptionSchema);

    const subscriptionsEntities = {
      ...subscriptions.entities,
      [props.id]: {
        ...(subscriptions.entities?.[props.id] || {}),
        ...(entities?.[props.id] || {}),
      },
    };

    const isIncludesIdInArray = subscriptions.ids.includes(props.id);

    return dispatch({
      type: FETCH_SUBSCRIBE_SUCCESS,
      payload: {
        entities: subscriptionsEntities,
        ids: [...subscriptions.ids, ...(!isIncludesIdInArray ? ids : [])],
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SUBSCRIBE_FAILURE,
      payload: {},
    });
  }
};

export const getPaymentIframe = props => async (dispatch, getState) => {
  const { service, tariffId, history } = props;

  try {
    const state = getState();

    const { token, refreshToken } = getTokensData(state, props);

    const response = await jwtRequest({
      callbackUrl: `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/subscriptions/service/${service.id}/tariff/${tariffId}`,
      token,
      refreshToken,
      locale: props.intl.locale,
      dispatch,
      method: 'post',
    });

    const { paymentForm = '', subscription = {} } = response;

    if (paymentForm) {
      /* eslint no-eval: 0 */
      eval(atob(paymentForm));

      const timer = setInterval(() => {
        if (!window.PG) return;
        window.PG.success(async () => {
          try {
            dispatch(getSubscribeService(subscription)).then(() => {
              history.push(MENU_ITEMS.mySubscriptionsPage.url);
            });
          } catch (err) {
            dispatch(
              getSubscribeService({
                service,
                status: SUBSCRIPTION_STATES.error,
              })
            ).then(() => {
              history.push(MENU_ITEMS.mySubscriptionsPage.url);
            });
          }
        });

        const iframe = document.getElementById('portmonepaymentframe');

        if (!iframe) {
          clearInterval(timer);
        }

        iframe.style.display = 'block';

        const observer = new MutationObserver(mutations => {
          mutations.forEach(async mutation => {
            if (mutation.attributeName !== 'style') return;
            const isCloseForm = mutation.target.style.display === 'none';

            if (isCloseForm) {
              await jwtRequest({
                callbackUrl: `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/subscriptions/${subscription.id}`,
                token,
                refreshToken,
                locale: props.intl.locale,
                dispatch,
                method: 'delete',
              });
            }
          });
        });

        observer.observe(iframe, {
          attributes: true,
          attributeFilter: ['style'],
        });

        clearInterval(timer);
      }, 100);
    }
  } catch (error) {
    dispatch(
      getSubscribeService({
        ...props,
        service,
        status: SUBSCRIPTION_STATES.error,
      })
    ).then(() => {
      history.push(MENU_ITEMS.mySubscriptionsPage.url);
    });
  }
};

export const setActiveService = props => async (dispatch, getState) => {
  const { id } = props;

  try {
    const state = getState();
    const { token, refreshToken } = getTokensData(state, props);

    const timer = setInterval(async () => {
      try {
        const response = await jwtRequest({
          callbackUrl: `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/subscriptions/${id}`,
          token,
          refreshToken,
          locale: props.intl.locale,
          dispatch,
        });

        const servicePaidStatus =
          response.status === SUBSCRIPTION_STATES.active;

        if (servicePaidStatus) {
          clearInterval(timer);
          dispatch(getSubscribeService(response));
        }
      } catch (error) {
        clearInterval(timer);
      }
    }, 5000);
  } catch (error) {
    dispatch(
      getSubscribeService({
        ...props,
        status: SUBSCRIPTION_STATES.error,
      })
    );
  }
};
