import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {};

const token = createReducer(initialState, {
  [FETCH_TOKEN_REQUEST]: (state, action) => ({
    ...state,
    data: action.payload,
    isFetching: true,
    isRequestFailed: false,
    isNotAuthorized: false,
    isLoaded: false,
  }),

  [FETCH_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload,
    isFetching: false,
    isRequestFailed: false,
    isNotAuthorized: false,
    isLoaded: true,
  }),

  [FETCH_TOKEN_FAILURE]: (state, action) => ({
    ...state,
    data: action.payload,
    isFetching: false,
    isRequestFailed: true,
    isNotAuthorized: action.payload.isNotAuthorized,
    isLoaded: false,
  }),
});

export default token;
