import {
  FETCH_TARIFFS_REQUEST,
  FETCH_TARIFFS_SUCCESS,
  FETCH_TARIFFS_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {};

const tariffs = createReducer(initialState, {
  [FETCH_TARIFFS_REQUEST]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_TARIFFS_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: false,
    isLoaded: true,
  }),

  [FETCH_TARIFFS_FAILURE]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: true,
    isLoaded: false,
  }),
});

export default tariffs;
