/* stylelint-disable */
import { createGlobalStyle } from 'styled-components';
import { fontFamilyPrimary } from './variables';

export const GlobalStyle = createGlobalStyle`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  #root ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  #root ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }

  #root ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    max-height: 50px;
  }

  button {
    border: 0;
    background-color: rgba(0, 0, 0, 0);

    &:focus {
      outline: none;
    }
  }

  .InnerHTML {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      i {
        color: #df3014;
        font-style: normal;
      }
    }

    h1,
    h2,
    h3 {
      margin-bottom: 16px;

      @media (min-width: 640px) {
        margin-bottom: 24px;
      }
    }

    h4,
    h5,
    h6 {
      margin-bottom: 16px;
    }

    h1 {
      font-family: 'Raleway', sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: #2d2d2d;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }

      @media (min-width: 1216px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    h2 {
      font-family: 'Raleway', sans-serif;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: #2d2d2d;

      @media (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
      }

      @media (min-width: 1216px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    h3 {
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: #2d2d2d;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }

      @media (min-width: 1216px) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    h4 {
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      font-weight: 700;
      color: #2d2d2d;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    h5 {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: #2d2d2d;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 18px;
      }

      @media (min-width: 1216px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    h6 {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #2d2d2d;
    }

    & div.table-container {
      margin: 0 -16px 16px;
    }

    & div.table-container > div {
      overflow: auto;
    }

    & div.table-container > div > div {
      padding: 0 16px;
      min-width: 630px;
    }

    & table {
      border-bottom: none;
      border-collapse: collapse;
      border-top: 1px solid #e4eaf4;
      line-height: 20px;
      table-layout: unset;
      text-align: left;
      width: 100%;
      margin-bottom: 16px;

      @media (min-width: 640px) {
        margin-bottom: 24px;
      }

      thead {
        background-color: #f8fafb;
        font-weight: 700;
      }

      tr {
        width: 100%;
        border-bottom: 1px solid #e4eaf4;

        td {
          padding: 16px;
          color: #2b2b2b;
          min-width: 80px;
        }

        td:first-child {
          min-width: 40px;
        }

        th {
          padding: 16px;
          color: #575757;
          font-weight: normal;
        }
      }
    }

    & div.table-container table {
      margin-bottom: 0;
    }

    & hr {
      background: #fff;
      border: 0;
      border-top: 1px solid #cbdaf4;
      margin: 32px 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    & a {
      word-break: break-all;
      color: #244696;
    }

    & a.no-break {
      word-break: normal;
    }

    & p,
    & ul,
    & ol,
    & a {
      font-size: 14px;
      line-height: 24px;

      @media (min-width: 640px) {
        font-size: 16px;
        line-height: 28px;
      }
    }

    & p,
    & ul,
    & ol {
      margin-bottom: 16px;

      @media (min-width: 640px) {
        margin-bottom: 24px;
      }
    }

    & ul > li::before {
      content: '•';
      color: #df3014;
      margin-right: 12px;
    }

    & ul.dashed-list > li:before {
      content: '-';
      color: #2d2d2d;
      margin-right: 2px;
    }

    & ol {
      counter-reset: my-counter;
      list-style: none;
      padding-left: 40px;
    }

    & ol > li {
      margin: 0 0 0.5rem 0;
      counter-increment: my-counter;
      position: relative;
    }

    & ol > li::before {
      content: counter(my-counter);
      color: #fff;
      font-weight: 900;
      font-size: 12px;
      position: absolute;
      left: -40px;
      line-height: 24px;
      width: 24px;
      height: 24px;
      top: 0;
      background: #df3014;
      border-radius: 50%;
      text-align: center;
    }

    & ol > li > p,
    & ul > li > p {
      display: inline;
      margin: 0;
    }

    & ol.normal-arabic-list {
      list-style-type: decimal;
    }

    & ol.normal-arabic-list > li {
      position: static;
    }

    & ol.normal-arabic-list > li:before {
      content: '';
      display: none;
    }

    & p:has(+ ul) {
      margin-bottom: 16px;
    }

    & img {
      display: block;
    }

    & blockquote {
      font-size: 18px;
      line-height: 32px;
      padding: 32px;
      width: 100%;
      background-color: #fdf5f3;
      margin-bottom: 16px;
      color: #000;
      border-radius: 2px;
      border-left: 4px solid #df3014;

      @media (min-width: 640px) {
        padding: 40px;
      }
    }
    
    & p.footer-text {
      text-align: center;
      font-size: 12px;
      line-height: 17px;
      color: #93a4ac;
      margin: 0;

      @media (min-width: 640px) {
        text-align: start;
      }
    }

    & div.iframe-container {
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;

      iframe {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
    }

    & p.list-item {
      display: flex;

      span:first-child {
        margin-right: 8px;
      }
    }
  }

  body {
    background-color: #e9ebee;
    font-family: ${fontFamilyPrimary};
  }

  .footer-wrapper, 
  .header-bottom__top, 
  .header-wrapper, 
  .popover-menu__wrapper, 
  .submenu-container {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
  }

  .mfz-section {
    position: relative;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 2px;
  }

  @media only screen and (max-width: 800px) {
    .mfz-section {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;
