export const scrollToElement = id => {
  const element = document.getElementById(id);
  const yOffset = -15;

  if (!element) {
    return;
  }

  const filterTop =
    element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: filterTop, behavior: 'smooth' });
};

export default scrollToElement;
