import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { useTemplateParams } from './useTemplateParams';

export const useSelectorProps = () => {
  const location = useLocation();
  const params = useParams();
  const intl = useIntl();
  const templateParams = useTemplateParams();

  const selectorProps = useMemo(
    () => ({ location, match: { params }, intl, templateParams }),
    [location, params, intl, templateParams]
  );

  return selectorProps;
};

export default useSelectorProps;
