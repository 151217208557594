import {
  FETCH_USER_PAYMENTS_REQUEST,
  FETCH_USER_PAYMENTS_SUCCESS,
  FETCH_USER_PAYMENTS_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  payments: {},
};

const user = createReducer(initialState, {
  [FETCH_USER_PAYMENTS_REQUEST]: (state, action) => ({
    ...state,
    payments: {
      ...state.payments,
      ...action.payload,
      isFetching: true,
      isRequestFailed: false,
      isLoaded: false,
    },
  }),

  [FETCH_USER_PAYMENTS_SUCCESS]: (state, action) => ({
    ...state,
    payments: {
      ...state.payments,
      ...action.payload,
      isFetching: false,
      isRequestFailed: false,
      isLoaded: true,
    },
  }),

  [FETCH_USER_PAYMENTS_FAILURE]: (state, action) => ({
    ...state,
    payments: {
      ...state.payments,
      ...action.payload,
      isFetching: false,
      isRequestFailed: true,
      isLoaded: false,
    },
  }),
});

export default user;
