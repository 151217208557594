import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getSeoMeta } from 'selectors';

import { useSelectorProps } from './useSelectorProps';

export const useSeoMeta = pageName => {
  const { formatMessage } = useIntl();
  const selectorProps = useSelectorProps();

  if (!pageName) {
    return {};
  }

  const {
    metaTitle,
    metaDescription,
    pageTitle,
    pageDescription,
  } = useSelector(state => getSeoMeta(state, selectorProps));

  const formattedMetaTitle =
    metaTitle ||
    formatMessage({
      id: `${pageName}.metaTitle`,
    });

  const formattedMetaDescription =
    metaDescription ||
    formatMessage({
      id: `${pageName}.metaDescription`,
    });

  const formattedTitle =
    metaTitle ||
    formatMessage({
      id: `${pageName}.metaTitle`,
    });

  const formattedPageTitle =
    pageTitle ||
    formatMessage({
      id: `${pageName}.pageTitle`,
    });
  const formattedPageDescription =
    pageDescription ||
    formatMessage({
      id: `${pageName}.pageDescription`,
    });

  return {
    title: formattedTitle,
    metaTitle: formattedMetaTitle,
    metaDescription: formattedMetaDescription,
    pageTitle: formattedPageTitle,
    pageDescription: formattedPageDescription,
  };
};

export default useSeoMeta;
