import { request } from 'utils';

import {
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
} from 'actionTypes';

import { makeGetTokens } from 'selectors';
import { NOT_AUTHORIZED_STATUS_CODE, TOKEN_TYPE } from 'constants/index';
import { stringify } from 'query-string';

const getTokens = makeGetTokens();

const getShouldFetchToken = (state, props) => {
  const { isFetching, isLoaded } = getTokens(state, props) || {};

  return !isFetching && !isLoaded;
};

export const fetchToken = props => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!getShouldFetchToken(state, props) || props?.isServer) {
      return true;
    }

    dispatch({
      type: FETCH_TOKEN_REQUEST,
      payload: {},
    });

    const requestURL = `${process.env.APP_MINFIN_API_DOMAIN}/api/jwt/token/`;

    const response = await request(requestURL, {
      method: 'post',
      withCredentials: true,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });

    return dispatch({
      type: FETCH_TOKEN_SUCCESS,
      payload: {
        ...response,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_TOKEN_FAILURE,
      payload: {
        isNotAuthorized: error?.response?.status === NOT_AUTHORIZED_STATUS_CODE,
      },
    });
  }
};

export const jwtRequest = async props => {
  const {
    token,
    refreshToken,
    callbackUrl,
    dispatch,
    locale,
    method,
    contentType,
    data,
  } = props;

  try {
    const requestURL = `${process.env.APP_MINFIN_API_DOMAIN}/api/jwt/token/validate/`;

    const { valid } = await request(requestURL, {
      method: 'post',
      headers: {
        Authorization: `${TOKEN_TYPE} ${token}`,
      },
    });

    const requestData = currentToken =>
      request(callbackUrl, {
        ...(method ? { method } : {}),
        headers: {
          ...(contentType ? { 'Content-Type': contentType } : {}),
          'Accept-Language': locale,
          Authorization: `${TOKEN_TYPE} ${currentToken}`,
        },
        ...(data ? { data } : {}),
      });

    if (valid) {
      return requestData(token);
    }

    try {
      dispatch({
        type: FETCH_TOKEN_REQUEST,
        payload: {},
      });

      const requestURLRefresh = `${process.env.APP_MINFIN_API_DOMAIN}/api/jwt/token/refresh/`;

      const response = await request(requestURLRefresh, {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: stringify({
          refresh_token: refreshToken,
        }),
      });

      dispatch({
        type: FETCH_TOKEN_SUCCESS,
        payload: {
          ...response,
        },
      });

      return requestData(response.token);
    } catch (error) {
      dispatch({
        type: FETCH_TOKEN_FAILURE,
      });

      return null;
    }
  } catch (error) {
    return console.log(error);
  }
};

// export const fetchRefreshToken = props => async dispatch => {
//   try {
//     dispatch({
//       type: FETCH_TOKEN_REQUEST,
//       payload: {},
//     });
//
//     return dispatch({
//       type: FETCH_TOKEN_SUCCESS,
//       payload: {
//         ...props,
//       },
//     });
//   } catch (error) {
//     return dispatch({
//       type: FETCH_TOKEN_FAILURE,
//     });
//   }
// };
