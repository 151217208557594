import { request } from 'utils';

import {
  FETCH_SEO_META_REQUEST,
  FETCH_SEO_META_SUCCESS,
  FETCH_SEO_META_FAILURE,
} from 'actionTypes';

import { makeGetSeoUrl, makeGetSeoMeta } from 'selectors';

const getSeoUrl = makeGetSeoUrl();
const getSeoMeta = makeGetSeoMeta();

const getShouldFetchSeoMeta = (state, props) => {
  const { isFetching, isLoaded } = getSeoMeta(state, props);

  return !(isFetching || isLoaded);
};

export const fetchSeoMeta = props => async (dispatch, getState) => {
  const state = getState();

  if (!getShouldFetchSeoMeta(state, props)) {
    return true;
  }

  const url = getSeoUrl(state, props);

  dispatch({
    type: FETCH_SEO_META_REQUEST,
    payload: { url },
  });

  const requestURL = `${process.env.APP_SEO_META_API_ROOT}?url=${url}`;

  try {
    const {
      data: {
        title: metaTitle,
        description: metaDescription,
        h1: pageTitle,
        h2: pageDescription,
      },
    } = await request(requestURL);

    return dispatch({
      type: FETCH_SEO_META_SUCCESS,
      payload: {
        url,
        metaTitle,
        metaDescription,
        pageTitle,
        pageDescription,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SEO_META_FAILURE,
      payload: { url },
    });
  }
};
