export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const FETCH_UNSUBSCIPTION_POOL_REQUEST =
  'FETCH_UNSUBSCIPTION_POOL_REQUEST';
export const FETCH_UNSUBSCIPTION_POOL_SUCCESS =
  'FETCH_UNSUBSCIPTION_POOL_SUCCESS';
export const FETCH_UNSUBSCIPTION_POOL_FAILURE =
  'FETCH_UNSUBSCIPTION_POOL_FAILURE';
export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
