export const colorWhite = '#fff';

export const colorLink400 = '#5076C0';

export const colorBlack = '#000';
export const colorBlack900 = '#111512';
export const colorBlack800 = '#252525';
export const colorBlack300 = '#454D49';
export const colorBlack200 = '#696F6B';

export const colorGreen = '#2D9095';
export const colorCyan = '#2e9096';

export const colorGray = '#454d49';
export const colorGray90 = '#e5e5e5';
export const colorGray100 = '#e6e6e6';
export const colorGray200 = '#CACCCB';
export const colorGray400 = '#87928C';
export const colorGraySemiLight = '#e5e8f1';
export const colorGrayLight = '#f2f3f2';

export const colorPrimary400 = '#35A47C';
export const colorPrimary600 = '#298662';
export const colorPrimaryDarken10 = '#298662';
export const colorPrimaryLighten95 = '#F3FAF7';
export const colorPrimaryLighten85 = '#D7EDE5';

export const colorSecondary400 = '#df3115';
export const colorSecondaryBasic = '#df3014';
export const colorSecondaryDarken10 = '#c72610';
export const colorSecondaryLighten20 = '#fdf3f1';

export const colorAccent = '#244696';
export const colorAccentDarken10 = '#203f87';
export const colorAccentLighten20 = '#506bab';
export const colorAccentLighten50 = '#91a3ca';
export const colorAccentLighten70 = '#bdc7df';
export const colorAccentLighten85 = '#dee3ef';
export const colorAccentLighten95 = '#f2f5f8';
export const colorAccent3 = '#ff7500';

export const colorBlueLight = '#cbdaf4';
export const colorBlueGray = '#cdd7e7';
export const colorBlueDark = '#3f5da3';

export const colorYellow = '#fac917';
export const colorRed = '#DB0107';

export const colorGreyLight = '#f9fafc';
