import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSeoRobots } from 'selectors';

import { getLocaleUrlPath } from 'utils';
// import { imgOgRu, imgOgUa } from 'assets/img';
import { ROBOTS, OG_ELEMENTS, LOCALES } from 'constants/seo';

import { useSelectorProps } from './useSelectorProps';

export const useMetaPage = (excludeRobots = false) => {
  const { locale } = useIntl();
  const { location } = useSelectorProps();
  const robots = useSelector(state => getSeoRobots(state));

  return useMemo(() => {
    let robotsContent;

    if (process.env.APP_DISABLE_CRAWLING === 'true') {
      robotsContent = ROBOTS.disableCrawling;
    } else {
      const indexRule = robots.noindex
        ? ROBOTS.index.noindex
        : ROBOTS.index.index;
      const followRule = robots.nofollow
        ? ROBOTS.follow.nofollow
        : ROBOTS.follow.follow;

      robotsContent = `${indexRule}, ${followRule}`;
    }

    return [
      {
        property: OG_ELEMENTS.image.property,
        content: `${process.env.APP_DEPLOY_URL}${
          process.env.APP_ASSETS_PUBLIC_PATH
        }asset/static/images/${locale === LOCALES.ru ? 'og_ru' : 'og_ua'}.jpg`,
      },
      {
        property: OG_ELEMENTS.type.property,
        content: OG_ELEMENTS.type.content,
      },

      {
        property: OG_ELEMENTS.url.property,
        content: `${process.env.APP_DEPLOY_URL}${getLocaleUrlPath(locale)}${
          process.env.APP_ROUTER_BASENAME
        }${location.pathname}`,
      },
      ...(excludeRobots
        ? []
        : [
            {
              name: ROBOTS.robots,
              content: robotsContent,
            },
          ]),
    ];
  }, [location.pathname, locale]);
};

export default useMetaPage;
