export const GTM_EVENT_CATEGORY = {
  adFreePage: 'adfree-page',
};

export const GTM_EVENT_ACTIONS = {
  click: 'click',
  scrollDepth: 'scrollDepth',
  activateButton: 'activate-button',
  activateSlider: 'activate-slider',
  blockVisibility: 'block-visibility',
};

export const GTM_EVENT_LABELS = {
  whyBanners: 'why-baners',
  enjoyContent: 'enjoy-content',
  whatForSubscription: 'what-for-subscription',
  tariffs: 'tarifs',
};
