import {
  FETCH_SEO_META_REQUEST,
  FETCH_SEO_META_SUCCESS,
  FETCH_SEO_META_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {};

const seo = createReducer(initialState, {
  [FETCH_SEO_META_REQUEST]: (state, action) => ({
    ...state,
    [action.payload.url]: {
      ...(state[action.payload.url] || {}),
      meta: {
        ...action.payload,
        isFetching: true,
        isRequestFailed: false,
        isLoaded: false,
      },
    },
  }),

  [FETCH_SEO_META_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.url]: {
      ...state[action.payload.url],
      meta: {
        ...action.payload,
        isFetching: false,
        isRequestFailed: false,
        isLoaded: true,
      },
    },
  }),

  [FETCH_SEO_META_FAILURE]: (state, action) => ({
    ...state,
    [action.payload.url]: {
      ...state[action.payload.url],
      meta: {
        ...action.payload,
        isFetching: false,
        isRequestFailed: true,
        isLoaded: false,
      },
    },
  }),
});

export default seo;
