export const ROBOTS = {
  robots: 'robots',
  disableCrawling: 'noindex, nofollow',
  index: {
    noindex: 'noindex',
    index: 'index',
  },
  follow: {
    nofollow: 'nofollow',
    follow: 'nofollow',
  },
};

export const LINKS_NO_FOLLOWS = 'nofollow noopener';

export const LOCALES = {
  ru: 'ru',
  uk: 'uk',
};

export const PUBLISHER = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  knowsLanguage: ['uk-UA', 'ru-UA'],
  url: 'https://minfin.com.ua/',
  logo: {
    '@type': 'ImageObject',
    url: 'https://minfin.com.ua/i/redesign/minfin-logo-ru.png',
  },
  foundingDate: '2008',
  sameAs: [
    'https://www.facebook.com/minfin.com.ua/',
    'https://t.me/Minfin_com_ua/',
    'https://www.youtube.com/channel/UCEW1QlFX_ito4gd3QXxyt5Q/',
    'https://twitter.com/MinfinComUa/',
    'https://www.instagram.com/minfin.com.ua/',
  ],
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+38(044)392-47-40',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: ['Ukrainian', 'Russian'],
      email: 'info@minfin.com.ua',
    },
  ],
};

export const SCHEMA = {
  type: {
    webPage: {
      '@context': 'https://schema.org/',
      '@type': 'WebPage',
    },
    webSite: {
      '@type': 'WebSite',
      url: 'https://finance.ua/',
    },
  },
};

export const REL = { alternate: 'alternate', canonical: 'canonical' };

export const OG_ELEMENTS = {
  image: {
    property: 'og:image',
  },
  type: {
    property: 'og:type',
    content: 'website',
  },
  url: {
    property: 'og:url',
  },
};
