import { normalize } from 'normalizr';

import {
  FETCH_USER_PAYMENTS_REQUEST,
  FETCH_USER_PAYMENTS_SUCCESS,
  FETCH_USER_PAYMENTS_FAILURE,
} from 'actionTypes';

import { userPaymentSchema } from 'schemas';
import { makeGetTokensData, makeGetUserPayments } from 'selectors';
import { jwtRequest } from 'actions/token';

const getUserPayments = makeGetUserPayments();
const getTokensData = makeGetTokensData();

const getShouldFetchUserPayments = (state, props) => {
  const { isFetching, isLoaded } = getUserPayments(state, props) || {};

  return !isFetching && !isLoaded;
};

const fetchUserPaymentsEntities = async (
  token,
  refreshToken,
  locale,
  dispatch
) => {
  const requestURL = `${process.env.APP_SUBCRIPTIONS_API_ROOT}/user/payments`;

  const data = await jwtRequest({
    callbackUrl: requestURL,
    token,
    refreshToken,
    locale,
    dispatch,
  });

  const {
    entities: { userPayment: entities = {} },
    result: ids = [],
  } = normalize(data, userPaymentSchema);

  return { entities, ids };
};

export const fetchUserPayments = props => async (dispatch, getState) => {
  try {
    const state = getState();

    const { token, refreshToken } = getTokensData(state, props);

    if (!getShouldFetchUserPayments(state, props) || !token) {
      return true;
    }

    dispatch({
      type: FETCH_USER_PAYMENTS_REQUEST,
      payload: {},
    });

    const response = await fetchUserPaymentsEntities(
      token,
      refreshToken,
      props.intl.locale,
      dispatch
    );

    return dispatch({
      type: FETCH_USER_PAYMENTS_SUCCESS,
      payload: {
        ...response,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_USER_PAYMENTS_FAILURE,
      payload: {},
    });
  }
};
