import { createSelector } from 'reselect';

import { getLocaleUrlPath } from 'utils';

const getSeo = state => state.seo;

export const makeGetSeoUrl = () => {
  return createSelector(
    (state, props) => props.location.pathname,
    (state, props) => props.locale || props.intl.locale,
    (pathname, locale) => {
      return `${getLocaleUrlPath(locale)}${
        process.env.APP_ROUTER_BASENAME
      }${pathname}`;
    }
  );
};

export const makeGetSeoByUrl = () => {
  const getSeoUrl = makeGetSeoUrl();

  return createSelector(getSeo, getSeoUrl, (seo, seoUrl) => {
    return seo[seoUrl] || {};
  });
};

export const makeGetSeoMeta = () => {
  const getSeoByUrl = makeGetSeoByUrl();

  return createSelector(getSeoByUrl, seo => seo.meta || {});
};

export const getSeoMeta = makeGetSeoMeta();

export const getSeoRobots = createSelector(
  makeGetSeoByUrl,
  seo => seo.robots || {}
);
