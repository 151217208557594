import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelectorProps } from 'hooks';
import { MENU_ITEMS } from 'constants/menu';

import { MenuWrapper, MenuList, LinkStyled } from './styles';

const Menu = () => {
  const selectorProps = useSelectorProps();
  const {
    location: { pathname },
  } = selectorProps;

  return (
    <MenuWrapper>
      <MenuList>
        {Object.keys(MENU_ITEMS).map(key => {
          const { id, url } = MENU_ITEMS[key];
          return (
            <LinkStyled key={id} to={url} $isActive={url === pathname}>
              <FormattedMessage id={`menu.item.${id}.title`} />
            </LinkStyled>
          );
        })}
      </MenuList>
    </MenuWrapper>
  );
};

export default Menu;
