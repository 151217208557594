export const PAGES = {
  landingPage: 'landingPage',
  mySubscriptionsPage: 'mySubscriptionsPage',
  paymentHistoryPage: 'paymentHistoryPage',
  chooseSubscriptionPage: 'chooseSubscriptionPage',
};

export const MENU_ITEMS = {
  mySubscriptionsPage: {
    id: PAGES.mySubscriptionsPage,
    url: '/my-subscriptions/',
  },
  paymentHistoryPage: {
    id: PAGES.paymentHistoryPage,
    url: '/payment-history/',
  },
};
