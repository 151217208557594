import React, { memo, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';

import routes from 'routes';

import { GlobalStyle, Reboot, Normalize } from '../../styles';

import Wrapper from './styles';

const App = memo(() => {
  const getRouteRender = useCallback(
    (RouteComponent, props) => () => <RouteComponent {...props} />,
    []
  );

  const renderRoute = useCallback(
    ({ component, id, props, ...routeProps }) => (
      <Route
        render={getRouteRender(component, props)}
        {...routeProps}
        key={id}
      />
    ),
    []
  );

  return (
    <Wrapper>
      <Normalize />
      <Reboot />
      <GlobalStyle />
      <Switch>{routes.map(renderRoute)}</Switch>
    </Wrapper>
  );
});

export default App;
