import { schema } from 'normalizr';
import { parseISO, format } from 'date-fns';

import { DEFAULT_DATE_FORMAT } from 'constants/date';

export const subscription = new schema.Entity(
  'subscription',
  {},
  {
    idAttribute: 'id',
    processStrategy: item => {
      const parsedDateTill = item.actualDateTill
        ? parseISO(item.actualDateTill)
        : '';

      return {
        ...item,
        actualDateFrom: item.actualDateFrom
          ? parseISO(item.actualDateFrom)
          : '',
        actualDateTill: parsedDateTill,
        formattedDateTill: parsedDateTill
          ? format(new Date(parsedDateTill), DEFAULT_DATE_FORMAT)
          : '',
        createdAt: item.createdAt ? parseISO(item.createdAt) : '',
        tariffPlan: {
          ...item.tariffPlan,

          pricePerMonth: Math.floor(
            +item.tariffPlan.price / +item.tariffPlan.monthsNumber
          ),
        },
      };
    },
  }
);

export const subscriptionSchema = [subscription];
