import styled from 'styled-components';

import { colorBlack, colorBlack900, mediaQueryMobileLargeMin } from 'styles';

export const Wrapper = styled.section`
  padding: 24px 16px;
  width: 100%;
  max-width: 632px;
  position: relative;

  @media ${mediaQueryMobileLargeMin} {
    margin: 0 auto;
  }
`;

export const PageTitle = styled.h2`
  margin-bottom: 8px;
  font: 700 18px/24px sans-serif;
  color: ${colorBlack};

  ${({ center }) => center && `text-align: center;`}
`;

export const PageDescription = styled.p`
  margin: 0;
  font: 400 14px/24px sans-serif;
  color: ${colorBlack900};

  ${({ center }) => center && `text-align: center;`}
`;
