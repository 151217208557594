import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { REL, LOCALES } from 'constants/seo';
import { getLocaleUrlPath } from '../utils';

export const usePageLinks = () => {
  const location = useLocation();
  const intl = useIntl();

  return useMemo(
    () => [
      {
        rel: REL.canonical,
        href: `${process.env.APP_DEPLOY_URL}${getLocaleUrlPath(intl.locale)}${
          process.env.APP_ROUTER_BASENAME
        }${location.pathname}`,
      },
      {
        rel: REL.alternate,
        hrefLang: LOCALES.uk,
        href: `${process.env.APP_DEPLOY_URL}/ua${process.env.APP_ROUTER_BASENAME}${location.pathname}${location.search}`,
      },
      {
        rel: REL.alternate,
        hrefLang: LOCALES.ru,
        href: `${process.env.APP_DEPLOY_URL}${process.env.APP_ROUTER_BASENAME}${location.pathname}${location.search}`,
      },
    ],
    [location.pathname, location.search, intl.locale]
  );
};

export default usePageLinks;
