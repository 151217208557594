import { normalize } from 'normalizr';
import { request } from 'utils';

import {
  FETCH_TARIFFS_REQUEST,
  FETCH_TARIFFS_SUCCESS,
  FETCH_TARIFFS_FAILURE,
} from 'actionTypes';

import { tariffSchema } from 'schemas';
import { makeGetTariffs, getServicesIds } from 'selectors';

const getTariffs = makeGetTariffs();

const getShouldFetchTariffs = (state, props) => {
  const { isFetching, isLoaded } = getTariffs(state, props) || {};

  return !isFetching && !isLoaded;
};

const fetchTariffsEntities = async (serviceId, locale) => {
  const requestURL = `${process.env.APP_SUBCRIPTIONS_API_ROOT}/services/${serviceId}/tariff-plans`;

  const data = await request(requestURL, {
    headers: {
      'Accept-Language': locale,
    },
  });

  const {
    entities: { tariff: entities = {} },
    result: ids = [],
  } = normalize(data, tariffSchema);

  return data.length ? { entities, ids } : {};
};

export const fetchTariffs = props => async (dispatch, getState) => {
  try {
    const state = getState();
    const servicesIds = getServicesIds(state);

    if (!getShouldFetchTariffs(state, props)) {
      return true;
    }

    dispatch({
      type: FETCH_TARIFFS_REQUEST,
      payload: {},
    });

    const data = await Promise.all(
      servicesIds.map(serviceId =>
        fetchTariffsEntities(serviceId, props.intl.locale)
      )
    );

    const results = servicesIds.reduce(
      (acc, id, index) => ({
        ...acc,
        [id]: data[index].entities,
      }),
      {}
    );

    return dispatch({
      type: FETCH_TARIFFS_SUCCESS,
      payload: {
        entities: results,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_TARIFFS_FAILURE,
      payload: {},
    });
  }
};
