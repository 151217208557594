import { schema } from 'normalizr';
import { PERIODS_KEYS } from 'constants/subscriptions';

export const tariff = new schema.Entity(
  'tariff',
  {},
  {
    idAttribute: ({ monthsNumber }) => monthsNumber,
    processStrategy: item => ({
      ...item,
      pricePerMonth: Math.floor(+item.price / +item.monthsNumber),
      durationPeriod:
        item.monthsNumber < 12 ? PERIODS_KEYS.month : PERIODS_KEYS.year,
    }),
  }
);

export const tariffSchema = [tariff];
