import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_UNSUBSCRIPTIONS_REQUEST,
  FETCH_UNSUBSCRIPTIONS_SUCCESS,
  FETCH_UNSUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIBE_REQUEST,
  FETCH_SUBSCRIBE_SUCCESS,
  FETCH_SUBSCRIBE_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {};

const subscriptions = createReducer(initialState, {
  [FETCH_SUBSCRIPTIONS_REQUEST]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_SUBSCRIPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: false,
    isLoaded: true,
  }),

  [FETCH_SUBSCRIPTIONS_FAILURE]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: true,
    isLoaded: false,
  }),

  [FETCH_UNSUBSCRIPTIONS_REQUEST]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_UNSUBSCRIPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: false,
    isLoaded: true,
  }),

  [FETCH_UNSUBSCRIPTIONS_FAILURE]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: true,
    isLoaded: false,
  }),

  [FETCH_SUBSCRIBE_REQUEST]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_SUBSCRIBE_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: false,
    isLoaded: true,
  }),

  [FETCH_SUBSCRIBE_FAILURE]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: true,
    isLoaded: false,
  }),
});

export default subscriptions;
