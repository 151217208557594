import loadable from '@loadable/component';

import {
  fetchSeoMeta,
  fetchServices,
  fetchTariffs,
  fetchUserSubscriptions,
  fetchUserPayments,
  fetchToken,
} from 'actions';
import Menu from 'components/Menu';

const MySubscriptionsPage = loadable(() => import('pages/MySubscriptionsPage'));
const PaymentHistoryPage = loadable(() => import('pages/PaymentHistoryPage'));
const ChooseSubscriptionPage = loadable(() =>
  import('pages/ChooseSubscriptionPage')
);
const LandingPage = loadable(() => import('pages/LandingPage'));
const NotFoundPage = loadable(() => import('pages/NotFoundPage'));

const routes = [
  {
    id: 'landingPage',
    path: ['/'],
    exact: true,
    strict: true,
    component: LandingPage,
    props: {
      loadData: async (dispatch, props) => {
        return Promise.all([
          dispatch(fetchServices(props)).then(() =>
            dispatch(fetchTariffs(props))
          ),
          dispatch(fetchToken(props)).then(() =>
            dispatch(fetchUserSubscriptions(props))
          ),
          dispatch(fetchSeoMeta(props)),
        ]);
      },
    },
  },
  {
    id: 'mySubscriptionsPage',
    path: ['/my-subscriptions/'],
    exact: true,
    strict: true,
    component: MySubscriptionsPage,
    menu: Menu,
    props: {
      loadData: async (dispatch, props) => {
        return Promise.all([
          dispatch(fetchServices(props)).then(() =>
            dispatch(fetchTariffs(props))
          ),
          dispatch(fetchToken(props)).then(() =>
            dispatch(fetchUserSubscriptions(props))
          ),
        ]);
      },
    },
  },
  {
    exact: true,
    strict: true,
    id: 'paymentHistoryPage',
    path: ['/payment-history/'],
    component: PaymentHistoryPage,
    menu: Menu,
    props: {
      loadData: async (dispatch, props) => {
        return Promise.all([
          dispatch(fetchToken(props)).then(() =>
            dispatch(fetchUserPayments(props))
          ),
        ]);
      },
    },
  },
  {
    exact: true,
    strict: true,
    id: 'chooseSubscriptionPage',
    path: ['/subscribe/'],
    component: ChooseSubscriptionPage,
    menu: Menu,
    props: {
      loadData: async (dispatch, props) => {
        return Promise.all([
          dispatch(fetchServices(props)).then(() =>
            dispatch(fetchTariffs(props))
          ),
          dispatch(fetchToken(props)).then(() =>
            dispatch(fetchUserSubscriptions(props))
          ),
        ]);
      },
    },
  },
  {
    id: 'notFoundPage',
    props: {},
    component: NotFoundPage,
  },
];

export default routes;
