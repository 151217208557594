export const getImageSrc = options => {
  const { name, device, url, width = 0, resizerParams = {} } = options;
  const params = {
    '2x': `&fit=cover&w=${width}&dpr=2&output=webp 2x`,
    '1x': `&fit=cover&w=${width}&output=webp 1x`,
    default: `&fit=cover&w=${width}&output=webp`,
    ...resizerParams,
  };

  return {
    srcSet: `${process.env.APP_IMAGE_RESIZER_URL}${name}.${device}.2x.png?url=${process.env.APP_DEPLOY_URL}${url}${params['2x']},
    ${process.env.APP_IMAGE_RESIZER_URL}${name}.${device}.1x.png?url=${process.env.APP_DEPLOY_URL}${url}${params['1x']}`,
    src: `${process.env.APP_IMAGE_RESIZER_URL}${name}.${device}.png?url=${process.env.APP_DEPLOY_URL}${url}${params.default}`,
  };
};

export default getImageSrc;
