import { combineReducers } from 'redux';

import seo from './seo';
import services from './services';
import tariffs from './tariffs';
import subscriptions from './subscriptions';
import user from './user';
import token from './token';

const rootReducer = combineReducers({
  seo,
  services,
  tariffs,
  subscriptions,
  user,
  token,
});

export default rootReducer;
