import { useMemo } from 'react';
import { getGADataAttrs } from 'utils';

export const useGADataAttrs = ({ category, label, action, show }) => {
  return useMemo(() => getGADataAttrs({ category, label, action, show }), [
    category,
    label,
    action,
    show,
  ]);
};

export default useGADataAttrs;
