import { createSelector } from 'reselect';

import { PERIOD, SUBSCRIPTION_STATES } from 'constants/index';

import { makeGetServices } from './services';
import { getTariffsEntities } from './tariffs';

const getSubscriptions = state => state.subscriptions;

export const makeGetSubscriptions = () => {
  return createSelector(getSubscriptions, i => i || {});
};

export const getUserSubscriptionsEntities = createSelector(
  getSubscriptions,
  data => data?.entities || {}
);

export const getUserSubscriptionsIds = createSelector(
  getSubscriptions,
  data => data?.ids || []
);

export const getUserSubscriptions = createSelector(
  getUserSubscriptionsEntities,
  getUserSubscriptionsIds,
  (items, ids) => (ids?.length ? ids.map(i => items[i]) : [])
);

export const getUserSubscriptionsWithServices = createSelector(
  getUserSubscriptions,
  getTariffsEntities,
  makeGetServices(),
  (subscriptions, tariffs, services) => {
    const isNotCanceledSubscription = subscriptions.find(
      item =>
        item?.status === SUBSCRIPTION_STATES.new ||
        item?.status === SUBSCRIPTION_STATES.active ||
        item?.status === SUBSCRIPTION_STATES.error
    );

    if (services?.entities && tariffs) {
      return Object.keys(services.entities)?.reduce(
        (res, cur) => {
          const service = services?.entities[cur];

          const defaultData = {
            id: parseInt(cur, 10),
            status: '',
            paymentOrderId: '',
            service,
            tariffPlan: {
              pricePerMonth: tariffs[cur][PERIOD.month].pricePerMonth,
            },
            formattedDateTill: '',
            actualDateFrom: '',
            actualDateTill: '',
            createdAt: '',
          };

          const isAnotherServiceInTheList = subscriptions.find(
            item => item?.service?.id !== service?.id
          );

          if (isAnotherServiceInTheList || !isNotCanceledSubscription) {
            return [defaultData, ...res];
          }

          return res;
        },
        [...subscriptions.sort((a, b) => (a.id < b.id ? 1 : -1))]
      );
    }

    return [];
  }
);
