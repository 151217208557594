import { createSelector } from 'reselect';
import { UNSUBSCRIBE_ANOTHER_REASON } from 'constants/subscriptions';

const getServices = state => state.services;
const getUnsibscribePool = state => state.services.unsubscribePool;

export const makeGetServices = () => {
  return createSelector(getServices, i => i || {});
};

export const makeGetUnsubscribePoll = () => {
  return createSelector(getServices, i => i.unsubscribePool || {});
};

export const getServicesIds = createSelector(
  getServices,
  data => data?.ids || []
);

export const getSelectedService = createSelector(
  getServices,
  data =>
    data?.selectedServiceId &&
    data?.entities &&
    data.entities[data.selectedServiceId]
);

export const getServiceById = createSelector(
  getServices,
  (state, props) => props,
  (data, { id }) => data?.entities?.[id] || null
);

export const isUnsibscribePoolLoaded = createSelector(
  getUnsibscribePool,
  data => data?.isLoaded
);

export const getUnsibscribePoolOptions = createSelector(
  getUnsibscribePool,
  (state, props) => props,
  (items, { intl: { formatMessage } }) => {
    return items?.data
      ? [...items.data, UNSUBSCRIBE_ANOTHER_REASON].map((i, index) => ({
          id: index,
          title:
            UNSUBSCRIBE_ANOTHER_REASON === i
              ? formatMessage({
                  id: 'anotherReason.label',
                })
              : i,
          isAnotherOption: UNSUBSCRIBE_ANOTHER_REASON === i,
        }))
      : [];
  }
);
