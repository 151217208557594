import { schema } from 'normalizr';

export const service = new schema.Entity(
  'service',
  {},
  {
    idAttribute: 'id',
  }
);

export const serviceSchema = [service];
