import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Wrapper } from 'components/Page/styles';
import { colorBlack900, colorSecondary400, colorGray100 } from 'styles';

export const MenuWrapper = styled.div`
  padding: 0;
  width: 100%;
  overflow: auto;
  box-shadow: inset 0px -1px 0px ${colorGray100};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuList = styled(Wrapper)`
  display: flex;
  height: 48px;
  padding: 0 16px;
`;

export const LinkStyled = styled(Link)`
  font: bold 12px/16px sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 16px;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: ${({ $isActive }) => ($isActive ? colorSecondary400 : colorBlack900)};
  transition: color 0.2s;

  &:hover {
    color: ${colorSecondary400};
  }

  ${({ $isActive }) =>
    $isActive &&
    `
    box-shadow: inset 0px -3px 0px ${colorSecondary400};
  `}
`;

export default MenuWrapper;
