export const DEFAULT_LOCALE = 'ru';
export const SUPPORTED_LOCALES = ['ru', 'uk'];

export const getLocaleUrlCode = locale => {
  if (locale.toLowerCase() === 'uk') {
    return 'ua';
  }

  return locale.toLowerCase();
};

export const getLocaleUrlPath = locale => {
  if (locale.toLowerCase() === 'ru') {
    return '';
  }

  return `/${getLocaleUrlCode(locale)}`;
};

export const getLocaleInDBFormat = locale => {
  if (!locale) {
    return 'ru';
  }

  if (locale === 'ua') {
    return 'uk';
  }

  return locale;
};

export const getLocaleISOCode = locale => {
  if (locale) {
    return `${locale}-UA`;
  }

  return `ru-UA`;
};
