export const LOCALE = {
  ru: 'ru',
  uk: 'uk',
};

export const LANGUAGE = {
  ru: 'ru',
  ua: 'ua',
};

export const LOCALE_PREFIX = {
  ru: LANGUAGE.ru,
  uk: LANGUAGE.ua,
};
