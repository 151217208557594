export const mobileMin = 480;
export const mobileLargeMin = 640;
export const tabletSmallMin = 688;
export const tabletMin = 768;
export const laptopMin = 960;
export const desctopMin = 1216;

export const mediaQueryMobileSmallMax = `(max-width: ${mobileMin - 1}px)`;
export const mediaQueryLaptopMax = `(max-width: ${laptopMin - 1}px)`;

export const mediaQueryMobileMin = `(min-width: ${mobileMin}px)`;
export const mediaQueryMobileLargeMin = `(min-width: ${mobileLargeMin}px)`;
export const mediaQueryTabletSmallMin = `(min-width: ${tabletSmallMin}px)`;
export const mediaQueryTabletMin = `(min-width: ${tabletMin}px)`;
export const mediaQueryLaptopMin = `(min-width: ${laptopMin}px)`;
export const mediaQueryDesktopMin = `(min-width: ${desctopMin}px)`;
