import { createSelector } from 'reselect';

const getTariffs = state => state.tariffs;

export const makeGetTariffs = () => {
  return createSelector(getTariffs, i => i || {});
};

export const isTariffsLoaded = createSelector(
  getTariffs,
  data => !!data?.isLoaded
);

export const isTariffsFetching = createSelector(
  getTariffs,
  data => !!data?.isFetching
);

export const getTariffsEntities = createSelector(
  getTariffs,
  data => data?.entities || null
);

export const getTariffsIds = createSelector(
  getTariffs,
  data => data?.ids || {}
);

export const getTariffsList = createSelector(
  getTariffs,
  (state, props) => props,
  (data, { serviceId }) => {
    const entities = data?.entities?.[serviceId];
    return entities ? Object.keys(entities).map(key => entities[key]) : [];
  }
);
