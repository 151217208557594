export const SUPPORT_EMAIL = 'support@minfin.com.ua';
export const PHONE_NUMBER = '0 800 307 555';

export const SWITCH_STATUSES = {
  withSubscribe: 'withSubscribe',
  withoutSubscribe: 'withoutSubscribe',
};

export const DEVICES = {
  desktop: 'desktop',
  mobile: 'mobile',
  all: 'all',
};

export const SUBSCRIPTION_STATES = {
  new: 'new',
  active: 'active',
  pending: 'pending',
  cancelled: 'cancelled',
  archived: 'archived',
  error: 'error',
};

export const SUBSCRIPTION_ACTIONS = {
  new: 'new',
  retry: 'retry',
  cancel: 'cancel',
};

export const SERVICES_ICONS_MAP = {
  1: {
    name: 'subscriptionEye',
    width: 56,
    height: 56,
    alt: 'ad-free subscription',
  },
};

export const PERIODS_KEYS = { month: 'month', year: 'year' };
export const SUBSCRIBE_TEXT_IDS = [1, 2, 3];
export const DEFAULT_ACTIVE_PERIOD = 2;

export const PAYMENT_CALLBACK_STATUSES = {
  error: 'error',
  success: 'success',
};

export const UNSUBSCRIBE_ANOTHER_REASON = 'anotherReason.label';
