import { LOCALE_PREFIX } from 'constants/index';

const getLocalePrefix = ({ locale }) => {
  if (locale === LOCALE_PREFIX.ru) {
    return '';
  }
  return `${LOCALE_PREFIX.uk}/`;
};

const originUrl = ' https://minfin.com.ua/';

const originUrlWithLocale = locale =>
  `${originUrl}${getLocalePrefix({ locale })}`;

export const privacyPolicyUrl = locale =>
  `${originUrlWithLocale(locale)}policy/`;

export const adfreeAgreementUrl = locale =>
  `${originUrlWithLocale(locale)}adfree-agreement/`;
