import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  SET_SELECTED_SERVICE,
  FETCH_UNSUBSCIPTION_POOL_REQUEST,
  FETCH_UNSUBSCIPTION_POOL_SUCCESS,
  FETCH_UNSUBSCIPTION_POOL_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  unsubscribePool: {},
};

const services = createReducer(initialState, {
  [FETCH_SERVICES_REQUEST]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_SERVICES_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: false,
    isLoaded: true,
  }),

  [FETCH_SERVICES_FAILURE]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: true,
    isLoaded: false,
  }),

  [SET_SELECTED_SERVICE]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isRequestFailed: true,
    isLoaded: false,
  }),

  [FETCH_UNSUBSCIPTION_POOL_REQUEST]: (state, { payload }) => ({
    ...state,
    unsubscribePool: {
      ...payload,
      isFetching: true,
      isRequestFailed: false,
      isLoaded: false,
    },
  }),

  [FETCH_UNSUBSCIPTION_POOL_SUCCESS]: (state, { payload }) => ({
    ...state,
    unsubscribePool: {
      ...payload,
      isFetching: false,
      isRequestFailed: false,
      isLoaded: true,
    },
  }),

  [FETCH_UNSUBSCIPTION_POOL_FAILURE]: (state, { payload }) => ({
    ...state,
    unsubscribePool: {
      ...payload,
      isFetching: false,
      isRequestFailed: true,
      isLoaded: false,
    },
  }),
});

export default services;
